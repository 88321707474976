import React from "react"
import Joi from "joi-browser"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from "../components/common/form"
import { changePassword } from "../services/authService"
import SpinnerLoading from "../components/common/spinnerLoading"
import { toast } from "react-toastify"

class Reset extends Form {
  state = {
    data: { email: "" },
    errors: {},
    isLoggedIn: false,
    loading: false,
    emailSent: false,
  }

  schema = {
    email: Joi.string()
      .email()
      .required()
      .label("Email"),
  }

  doSubmit = async e => {
    e.preventDefault()
    if (this.state.loading) {
      return
    }
    this.setState({ loading: true })

    let errors = this.validate()
    this.setState({ errors: errors || {} })
    if (errors) {
      this.setState({ loading: false })
      toast.error("Sono stati riscontrati degli errori di compilazione")
      return
    }
    if (errors === null) {
      errors = {}
    }

    const { data } = this.state
    if ((await changePassword(data.email)) === true) {
      this.setState({ loading: false, emailSent: true })
    } else {
      this.setState({ loading: false })
    }
  }

  render() {
    const { loading, emailSent } = this.state
    return (
      <Layout>
        <SEO
          title="Reset password"
          keywords={[`vision care`]}
          description="Reset password"
        />
        {loading && (
          <div className="mt-5 pt-5">
            <SpinnerLoading />
          </div>
        )}
        {!loading && (
          <div className={"container"}>
            <div className="row">
              <div className="col-12">
                <h1 className={"page-title"}>Recupero password</h1>
                {emailSent && (
                  <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                      Controlla la tua email e segui le istruzioni in essa
                      contenute per poter resettare la tua password
                    </div>
                  </div>
                )}
                {!emailSent && (
                  <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                      <form onSubmit={this.handleSubmit} className="pb-5">
                        <div className="form-group">
                          {this.renderInput(
                            "email",
                            "Inserisci la tua email",
                            "text",
                            false,
                            "bootstrap"
                          )}
                        </div>

                        <div className="form-group">
                          {this.renderButton("Invia", true)}
                        </div>

                        <ul>
                          <li className="my-0">
                            <a href="/login">
                              <span className="text-orange">
                                Torna al login
                              </span>
                            </a>
                          </li>
                        </ul>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Layout>
    )
  }
}

export default Reset
